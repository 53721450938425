export const GOOGLE_ANALYTICS_ID = "G-BM099RRJ37";

export const GOOGLE_NETWORK_CODE = "21916527510";

export const GOOGLE_OPTIMIZE_ID = "GTM-TM4SN5K";

const availableCodes = [
  "gameqik-article-top-banner",
  "gameqik-article-sidebar",
  "gameqik-review-top-banner",
  "gameqik-review-sidebar",
];

export function initDFP() {
  window.googletag = window.googletag || { cmd: [] };
  googletag.cmd.push(() => {
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });
}

export function setDFP(ads) {
  googletag.cmd.push(() => {
    googletag.destroySlots();
    const slots = ads.map((ad) => {
      if (!availableCodes.includes(ad.code)) {
        throw new Error(`Unknown ad code ${ad.code}`);
      }
      const mapping = ad.sizes
        .reduce((mapping, size) => mapping.addSize(size[0], size[1]), googletag.sizeMapping())
        .build();
      return googletag
        .defineSlot(`/${GOOGLE_NETWORK_CODE}/${ad.code}`, ad.size, ad.id)
        .defineSizeMapping(mapping)
        .updateTargetingFromMap(getClickTargetingMap())
        .addService(googletag.pubads());
    });
    slots.forEach((slot) => googletag.display(slot));
  });
}

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

export function pageview(path) {
  gtag("config", GOOGLE_ANALYTICS_ID, {
    page_path: path,
  });
}

function getClickTargetingMap() {
  const url = new URL(window.location.href);
  const gclid = url.searchParams.get("gclid");
  const msclkid = url.searchParams.get("msclkid");

  if (gclid && msclkid) {
    // both !? we return nothing
    return {};
  } else if (gclid) {
    return {
      "click-id": `g:${gclid}`,
    };
  } else if (msclkid) {
    return {
      "click-id": `m:${msclkid}`,
    };
  } else {
    // neither, default, return nothing
    return {};
  }
}

export function initAnalytics(analytics) {
  // analytics includes things like { experiments, content_group1, etc }
  gtag("js", new Date());
  gtag("config", GOOGLE_ANALYTICS_ID, {
    ...analytics,
    content_group3: "trafficcop", // Ads Provider
    optimize_id: GOOGLE_OPTIMIZE_ID,
    custom_map: {
      dimension1: "gclid",
      dimension2: "msclkid",
    },
  });
  const url = new URL(window.location.href);
  for (let key of ["gclid", "msclkid"]) {
    const clkid = url.searchParams.get(key);
    if (!clkid) {
      continue;
    }
    gtag("event", `${key}_dimension`, {
      [key]: clkid,
      non_interaction: true,
    });
  }
}
