import fetch from "isomorphic-unfetch";
import getConfig from "next/config";
import * as firebase from "firebase/app";
// import * as Sentry from "@sentry/node";

import Redis from "ioredis";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiHost = serverRuntimeConfig.apiHost
  ? serverRuntimeConfig.apiHost
  : publicRuntimeConfig.apiHost;
const redisHost = serverRuntimeConfig.redisHost;

export const AUTHORIZED_UIDS = {
  zkrwx0jMPhfiahSoxVqfgcTeqdw2: "James",
  SJney7WbaoSoCNoSGkb6B982UDb2: "Henry",
};

export async function nodePost(path, params) {
  /*
  This is to post to the 'frontend api' as oppposed to the 'backend api'.

  This allows caching logic for eg comments
  to be handled on the frontend, rather than at the backend API level.
  */
  const url = "/api" + path;
  const token = await firebase.auth().currentUser.getIdToken();
  const args = {
    method: "POST",
    header: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ ...params, token }),
  };
  const result = await fetch(url, args);
  return await result.json();
}

export async function nodeGet(path, params) {
  /*

  Should only be called from the client.

  */
  const url = new URL("/api" + path);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const result = await fetch(url.toString());
  if (result.status !== 200) {
    const error = new Error(`API returned ${result.status} for ${path}`);
    // Sentry.captureException(error);
    throw error;
  }
  return await result.json();
}

export async function apiPost(path, params) {
  /*

  Can be called from client or server.

  */
  const url = apiHost + path;
  const args = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };
  const result = await fetch(url, args);
  return await result.json();
}

export async function apiGet(path, params = {}) {
  /*

  Can be called from the client or server.

  */
  const url = new URL(apiHost + path);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const result = await fetch(url.toString());
  if (result.status !== 200) {
    const error = new Error(`API returned ${result.status} for ${path}`);
    error.statusCode = 404;
    // Sentry.captureException(error);
    throw error;
  }
  return await result.json();
}

const MAX_AGE = 60 * 60; // 1 hour
let redis;

export async function maybeCached({ key, path, params, cached }) {
  key = key + ":v2";
  if (!redis && redisHost) {
    redis = new Redis(redisHost);
  }
  if (redis) {
    let blob = await redis.get(key);
    if (!cached || !blob) {
      const data = await apiGet(path, params);
      blob = JSON.stringify(data);
      await redis.set(key, blob, "ex", MAX_AGE);
    }
    return JSON.parse(blob);
  } else {
    return await apiGet(path, params);
  }
}

export async function getSearch({ q }) {
  return await apiGet(`/appcheeta/search/`, { q });
}

export async function getReview({ slug, cached }) {
  const path = `/appcheeta/review/${slug}/`;
  const key = `appcheeta:review-v3:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getComments({ entity_type, entity_slug, cached }) {
  const path = `/comments/list/`;
  const key = `appcheeta:comments:${entity_type}:${entity_slug}`;
  const params = { entity_type, entity_slug };
  return maybeCached({ key, path, params, cached });
}

export async function getArticle({ slug, cached }) {
  const path = `/appcheeta/article/${slug}/`;
  const key = `appcheeta:article:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getArticles({ cached }) {
  const path = `/appcheeta/articles/`;
  const key = `appcheeta:articles`;
  return await maybeCached({ key, path, cached });
}

export async function getArticlesByCategory({ cached, slug }) {
  const path = `/appcheeta/articles/`;
  const key = `appcheeta:articles:${slug}`;
  const params = { category: slug };
  return await maybeCached({ key, path, params, cached });
}

export async function getMenu({ cached }) {
  const path = `/appcheeta/menu/`;
  const key = `appcheeta:menu`;
  return await maybeCached({ key, path, cached });
}

export async function getHome({ cached }) {
  const path = `/appcheeta/home/`;
  const key = `appcheeta:home`;
  return await maybeCached({ key, path, cached });
}

export async function getGame({ slug, cached }) {
  const path = `/appcheeta/game/${slug}/`;
  const key = `appcheeta:game:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getGamesForPlatform({ slug, cached }) {
  const path = `/appcheeta/game/platform/${slug}/`;
  const key = `appcheeta:games-by-platform:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getGamesForCategory({ slug, page, cached }) {
  const path = `/appcheeta/game/category/${slug}/?page=${page}`;
  const key = `appcheeta:games-by-category:${slug}:${page}`;
  return await maybeCached({ key, path, cached });
}

export async function getApp({ slug, cached }) {
  const path = `/appcheeta/app/${slug}/`;
  const key = `appcheeta:app:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getAppsForPlatform({ slug, cached }) {
  const path = `/appcheeta/app/platform/${slug}/`;
  const key = `appcheeta:apps-by-platform:${slug}`;
  return await maybeCached({ key, path, cached });
}

export async function getAppsForCategory({ slug, page, cached }) {
  const path = `/appcheeta/app/category/${slug}/?page=${page}`;
  const key = `appcheeta:apps-by-category:${slug}:${page}`;
  return await maybeCached({ key, path, cached });
}

export async function getReviews({ slug, cached }) {
  const path = `/appcheeta/reviews/`;
  const key = `appcheeta:reviews`;
  return await maybeCached({ key, path, cached });
}
