import uuid4 from "uuid4";
import { action } from "./actions";
import { all, takeEvery, select, put } from "redux-saga/effects";
import { nodePost, AUTHORIZED_UIDS } from "../utils/api";

export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const NEW_COMMENT = "NEW_COMMENT";
export const SET_COMMENTS = "SET_COMMENTS";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const LIKE_COMMENT = "LIKE_COMMENT";
export const UNLIKE_COMMENT = "UNLIKE_COMMENT";
export const CANCEL_REPLY = "CANCEL_REPLY";
export const START_REPLY = "START_REPLY";

export const newComment = action(NEW_COMMENT, "parent_uid", "content");
export const addComment = action(ADD_COMMENT, "comment");
export const deleteComment = action(DELETE_COMMENT, "uid");
export const updateComment = action(UPDATE_COMMENT, "uid", "content");
export const setComments = action(SET_COMMENTS, "comments", "entity_type", "entity_slug");
export const likeComment = action(LIKE_COMMENT, "uid", "entity_type", "entity_slug");
export const unlikeComment = action(UNLIKE_COMMENT, "uid", "entity_type", "entity_slug");
export const startReply = action(START_REPLY, "uid");
export const cancelReply = action(CANCEL_REPLY, "uid");

const defaultComments = {
  comments: [],
  entity_type: null,
  slug: null,
  replying_to: null,
};

export function commentsReducer(state = defaultComments, action) {
  switch (action.type) {
    case SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
        entity_type: action.entity_type,
        entity_slug: action.entity_slug,
      };
    case START_REPLY:
      return {
        ...state,
        replying_to: action.uid,
      };
    case CANCEL_REPLY:
      return {
        ...state,
        replying_to: null,
      };
    case ADD_COMMENT:
      return {
        ...state,
        comments: state.comments.concat(action.comment),
        replying_to: null,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        comments: state.comments.filter((comment) => comment.uid !== action.uid),
        replying_to: null,
      };
    case LIKE_COMMENT:
      return {
        ...state,
        comments: state.comments.map((comment) => ({
          ...comment,
          likes: comment.likes + (comment.uid === action.uid),
        })),
      };
    case UNLIKE_COMMENT:
      return {
        ...state,
        comments: state.comments.map((comment) => ({
          ...comment,
          likes: comment.likes - (comment.uid === action.uid),
        })),
      };
    default:
      return state;
  }
}

const createdAscending = (a, b) => {
  a = new Date(a.created);
  b = new Date(b.created);
  return a.getTime() - b.getTime();
};

const createdDescending = (a, b) => {
  a = new Date(a.created);
  b = new Date(b.created);
  return b.getTime() - a.getTime();
};

export function selectComments(state) {
  const { comments, entity_type } = state.comments;
  let likes = [];
  if (state.user.profileLoaded) {
    const key = `${entity_type}_comments`;
    likes = state.user.likes[`${entity_type}_comments`];
  }
  const entries = [];
  const lookup = {};
  for (let comment of state.comments.comments.sort(createdAscending)) {
    const entry = {
      ...comment,
      liked: likes.includes(comment.uid),
      replying: comment.uid === state.comments.replying_to,
      canDelete: AUTHORIZED_UIDS[state.user.uid] || comment.user_uid === state.user.uid,
      comments: [],
    };
    lookup[entry.uid] = entry;
    if (entry.parent_uid) {
      // needs a parent
      if (lookup[entry.parent_uid]) {
        // we *have* the parent
        lookup[entry.parent_uid].comments.push(entry);
      }
    } else {
      // top level
      entries.push(entry);
    }
  }
  Object.keys(lookup).forEach((key) => (lookup[key].leaf = lookup[key].comments.length === 0));
  return entries.sort(createdDescending);
}

function getGrandparent(uid, comments) {
  /*
    Returns the top-most comment related to this `uid` where
    `comments` is an array of comments with the field `uid`.
    */

  // look for the comment
  const comment = comments.find((comment) => comment.uid === uid);
  if (comment.parent_uid) {
    // has a parent, so find its parent
    return getGrandparent(comment.parent_uid, comments);
  }
  return uid;
}

function* doNewComment(action) {
  /*
    Called when a user would like to create a new comment.
    */
  const { user, comments } = yield select();
  const { entity_type, entity_slug } = comments;
  const comment_uid = uuid4();
  const comment = {
    uid: comment_uid,
    user_uid: user.uid,
    name: user.name,
    created: new Date().toISOString(),
    content: action.content,
    parent_uid: action.parent_uid || null,
    grandparent_uid: action.parent_uid
      ? getGrandparent(action.parent_uid, comments.comments)
      : null,
    likes: 0,
    level: action.parent_uid
      ? comments.comments.find((comment) => comment.uid === action.parent_uid).level + 1
      : 0,
  };
  yield put(addComment({ comment }));
  yield nodePost("/user/comments/add", {
    comment_uid,
    entity_slug,
    entity_type,
    parent_uid: comment.parent_uid,
    grandparent_uid: comment.grandparent_uid,
    content: comment.content,
  });
}

function* doDeleteComment(action) {
  const { comments } = yield select();
  const { entity_type, entity_slug } = comments;
  const { uid } = action;
  yield nodePost("/user/comments/delete", {
    comment_uid: uid,
    entity_slug,
    entity_type,
  });
}

function* doUpdateComment(action) {
  const state = yield select();
  const { entity_type, entity_slug } = state.comments;
  yield nodePost("/comments/update", {
    content: action.content,
    comment_uid: action.uid,
    entity_slug,
    entity_type,
  });
}

export function* commentsSaga() {
  yield all([
    takeEvery(NEW_COMMENT, doNewComment),
    takeEvery(UPDATE_COMMENT, doUpdateComment),
    takeEvery(DELETE_COMMENT, doDeleteComment),
  ]);
}
