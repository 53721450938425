const light = {
  name: "Light",
  emphasisTextColor: "#333",
  primaryTextColor: "#555",
  secondaryTextColor: "#777",
  tertiaryTextColor: "#aaa",
  borderColor: "#ddd",
  primaryIconColor: "#666",
  secondaryIconColor: "#aaa",
  backgroundColor: "#fff",
  selectedBackgroundColor: "#eee",
  modalBackgroundColor: "rgba(0, 0, 0, .9)",
  primaryButtonBackgroundColor: "#8c59ea",
  primaryButtonTextColor: "#fff",
  secondaryButtonBackgroundColor: "#aaa",
  secondaryButtonTextColor: "#eee",
  buttonBorderColor: "#eee",
  accentTextColor: "#8c59ea",
};

const dark = {
  name: "Dark",
  emphasisTextColor: "#eee",
  primaryTextColor: "#ddd",
  secondaryTextColor: "#ccc",
  tertiaryTextColor: "#999",
  borderColor: "#444",
  primaryIconColor: "#ccc",
  secondaryIconColor: "#aaa",
  backgroundColor: "#222",
  selectedBackgroundColor: "#444",
  modalBackgroundColor: "rgba(0, 0, 0, .9)",
  primaryButtonBackgroundColor: "#8c59ea",
  primaryButtonTextColor: "#fff",
  secondaryButtonBackgroundColor: "#888",
  secondaryButtonTextColor: "#eee",
  buttonBorderColor: "none",
  accentTextColor: "#fca9fa",
};

export const themes = {
  light,
  dark,
};
